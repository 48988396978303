@charset 'UTF-8';
@import "/Users/alivevn/Website/vhost/ringo-pass/src/scss/utility/_flex.scss";
@import "/Users/alivevn/Website/vhost/ringo-pass/src/scss/utility/_media-queries.scss";
@import "/Users/alivevn/Website/vhost/ringo-pass/src/scss/utility/_mixin.scss";

#fullpage {
	position: relative;
	// overflow: hidden;
}
.box-scroll {
	width: 70px;
	position: absolute;
	top: 0;
	left: 0;
	height: calc(100vh + 225px);
	background: $green1;
	z-index: 99;
	.btn-scroll {
		text-align: center;
		font-weight: bold;
		position: absolute;
		top: 50vh;
		left: 0;
		right: 0;
		height: 100%;
		margin: -20px auto auto auto;
		a {
			position: relative;
			color: #fff;
			font-size: 12px;
			text-align: center;
			font-weight: bold !important;
			letter-spacing: 1px;
		    display: block;
		    padding: 0 10px 70px 10px;
		    i {
		    	word-break: keep-all;
		    	-moz-transform: rotate(-90deg);
			    -webkit-transform: rotate(-90deg);
			    -ms-transform: rotate(-90deg);
			    transform: rotate(-90deg);
			    font-style: normal;
			    display: block;
			    font-family: $comfortaa;
 				-webkit-font-smoothing: auto;
		    }
		}
	}
}
.fp-table,.fp-tableCell,.fp-scrollable {
	height: auto !important;
	display: block!important;
	table-layout: auto!important;
	overflow: inherit!important;
}
.fp-scroller {
	overflow: inherit!important;
}
#section1 {
	z-index: 94;
	position: relative;
}
#section2 {
	z-index: 95;
	position: relative;
}
#section3 {
	z-index: 96;
	position: relative;
}
#section4 {
	z-index: 94;
	position: relative;
}
.mainVisual {
	height: 100vh;
	position: relative;
	min-height: 600px;
	padding-left: 70px;
	margin-bottom: 130px;
	.wcm {
		height: 100vh;
		min-height: 600px;
		position: relative;
	}
	.box-intro {
		padding: 30px 0;
		position: absolute;
		top: 50%;
		left: 20px;
		right: 20px;
		-moz-transform: translateY(-50%);
	    -webkit-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	    transform: translateY(-50%);
	    max-width: 825px;
	    margin: auto;
		@include flexbox();
		@include flex-wrap(wrap);
		@include justify-content(space-between);
		@include PC {
			@media (max-width: 1024px) {
			  max-width: 650px;
			}
		}
	    .pic {
	    	max-width: 290px;
	    	width: 37%;
	    	@include order(1);
	    	@include PC {
				@media (max-height: 700px) {
				   max-width: 230px;
				}
			}
	    }
	    .info {
	    	margin-top: 0;
	    	@include order(2);
	    	@include PC {
				@media (max-height: 700px) {
				   margin-top: 0;
				}
			}
	    }
	    .logo {
	    	text-align: center;
	    	max-width: 260px;
	    	margin: auto auto 40px auto;
	    	img {width: 100%;}
	    	@include PC {
	    		@media (max-width: 1024px) {
				  max-width: 210px;
				}
				@media (max-height: 700px) {
					margin: auto auto 20px auto;
				   max-width: 210px;
				}
	    	}
	    	
	    }
	    .desc {
	    	text-align: center;
	    	font-weight: bold;
	    	@include font-size(26);
	    	@include PC {
	    		@media (max-width: 1024px) {
				  @include font-size(22);
				}
				@media (max-height: 700px) {
				   @include font-size(22);
				}
	    	}
	    	
	    	.ttl1 {
	    		font-family: $plus;
	    		font-weight: bold;
	    		@include font-size(26);
	    	}
	    	.ttl2 {
	    		font-family: $plus;
	    		display: block;
	    		font-weight: 500;
	    		@include font-size(38);
	    		margin-top: 8px;
	    		@include PC {
		    		@media (max-width: 1024px) {
					  @include font-size(35);
					}
					@media (max-height: 700px) {
					   @include font-size(33);
					}
		    	}
	    	}
	    	span {
	    		font-weight: 800;
	    		margin-right: 10px;
	    	}
	    	.logo-app {
	    		margin: 38px 0 26px 0;
	    		@include PC {
		    		@media (max-width: 1024px) {
					  margin: 20px 0 20px 0;
					}
					@media (max-height: 700px) {
					   margin: 20px 0 20px 0;
					}
		    	}
	    	}
	    	.small {
	    		@include font-size(16);
	    		font-weight: normal;
	    	}
	    }

	}
}
.sec-feature {
	padding-bottom: 215px;
	.rows {
		@include flexbox();
		@include flex-wrap(wrap);
		@include align-items(center);
		@include justify-content(space-between);
		.thumb {
			@include order(2);
			width: 45%;
			max-width: 530px;
		}
		.detail {
			width: 51%;
			ul {
				li {
					@include border-radius(10px);
					background: rgba(255,255,255,0.1);
					margin-bottom: 11px;
					display: table;
					width: 100%;
					min-height: 150px;
					.pic {
						background: rgba(255,255,255,0.2);
						width: 150px;
						@include border-radius(10px);
						display: table-cell;vertical-align: middle;
						text-align: center;
					}
					.right {
						padding: 10px 10px 10px 22px;
						display: table-cell;vertical-align: middle;
						color: #fff;
						.right-ttl {
							font-weight: bold;
							font-family: $plus;
							font-size: 26px;
							margin-bottom: 5px;
							span {
								font-size: 16px;
								margin-left: 10px;
								font-family: $comfortaa;
							}
						}
						p {
							font-weight: bold;
							letter-spacing: 1px;
						}
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
.sec-bike {
	padding-bottom: 160px;
	.cmn-title {
		margin-bottom: 50px;
		.ttl {
			small {
				margin-bottom: 17px;
			}
		}
	}
	.block-step {
		.step-num {
			text-align: center;
			color: #fff;
			width: 175px;
			@include border-radius(10px);
			font-size: 27px;
			font-family: $plus;
			font-weight: bold;
			background: $green2;
			padding: 3px 0;
		}
		.ttl-step {
			margin-bottom: 17px;
			line-height: 1.5;
			font-size: 30px;
			font-weight: bold;
			font-family: $plus;
		}
	}
	.block-step1 {
		max-width: 565px;
		margin: auto auto 55px auto;
		.content-accor {
			@include flexbox();
			@include flex-wrap(wrap);
			@include align-items(center);
			@include justify-content(space-between);
		}
		.pic {
			max-width: 165px;
			width: 30%;
			@include order(1);
		}
		.detail {
			margin-top: -35px;
			width: 62%;
			@include order(2);
		}
		.step-num {
			margin-bottom: 32px;
		}
	}
	.content-accor {
		max-width: 940px;
		margin: auto;
	}
	.list-bike {
		font-size: 0;
		text-align: center;
		// margin: 0 -4.3%;
		@include flexbox();
		@include flex-wrap(wrap);
		@include justify-content(space-between);
		li {
			// max-width: calc(300px + 4%);
			width: 33%;
			max-width: 252px;
			// display: inline-block;
			// vertical-align: top;
			// padding: 0 4.3%;
			.thumb {
				max-width: 252px;
				position: relative;
				text-align: center;
				margin: auto auto 28px auto;
				&.thumb2 {
					margin: 0 -20px 25px 0;
					max-width: 253px;
				}
				.per {
					position: absolute;
					content: "";
				    right: -85px;bottom: -20px;
				    @include max-screen(1100px) { 
			    		right: -35%;
					    bottom: -12%;
					    width: 40%;
			    	}
				}
				&.thumb3 {
					img {
						margin-top: 15px;
					}
				}
				&.thumb4 {
					img {
						margin-top: 13px;
					}
				}
				.note {
					position: absolute;
					top: -7px;
					right: -50px;
					display: inline-block;
					width: 177px;
					text-align: center;
					font-size: 14px;
					background: $yellow;
					@include border-radius(20px);
					line-height: 1.3;
					font-weight: bold;
					padding: 7px 5px;

				}
			}
			.num01 {
				span {
					text-align: center;
					color: #fff;
					font-size: 16px;
					font-family: $comfortaa;
					font-weight: bold;
					background: $green2;
					line-height: 27px;
					height: 25px;
					display: inline-block;
					width: 50px;
				}
			}
			.desc {
				margin-top: 10px;
				font-size: 16px;
				letter-spacing: -0.5px;
				text-align: center;
			}
			.cir {
				background: #FFEC7C;
				@include border-radius(50%);
				padding: 10px;
				height: 100%;
				min-height: 200px;
				max-height: 250px;
				max-width: 250px;
				position: relative;
				text-align: center;
				margin: auto;
				p {
					color: #434343;
					position: absolute;
					content: "";
					font-weight: bold;
					top: 48%;
					left: 0;
					right: 0;
					-moz-transform: translateY(-50%);
				    -webkit-transform: translateY(-50%);
				    -ms-transform: translateY(-50%);
				    transform: translateY(-50%);
				}
			}
		}
	}
	.block-step-white {
		background: #fff;
		@include border-radius(15px);
		padding: 51px 75px 49px 75px;
		margin-bottom: 20px;
		.heading {
			max-width: 940px;
			margin: auto auto 48px auto;
			.step-num {
				display: inline-block;
				vertical-align: middle;
				margin-right: 27px;
			}
			.ttl-step {
				display: inline-block;
				vertical-align: middle;
				color: $green1;
				margin-bottom: 0;
				max-width: calc(100% - 215px);
			}
		}
		&.step4 {
			.note-pink {
				text-align: center;
				margin: 32px 0 20px 0;
			}
			.heading {
				margin: auto auto 35px auto;
			}
			.list-bike {
				li {
					.thumb {
						margin-bottom: 25px;
					}
				}
			}
		}
		&.step5 {
			.heading {
				margin: auto auto 52px auto;
			}
			.desc1 {
				text-align: center;
				margin-bottom: 35px;
			}
			.pic {
				text-align: center;
				max-width: 550px;
				margin: auto;
				position: relative;
				z-index: 99;
			}
			.box-img {
				margin: auto;
				max-width: 966px;
				position: relative;
				&-desc {
					text-align: center;
					margin: 40px 0 0 0;
				}
			}
		}
	}
}
.sec-taxi {
	.cmn-title {
		margin-bottom: 83px;
	}
	.ttl-taxi {
		text-align: center;
		position: relative;
		z-index: 99;
		margin-bottom: 70px;
		span {
			background: #fff;
			@include border-radius(35px);
			display: inline-block;
			text-align: center;
			padding: 10px 10px;
			font-size: 30px;
			color: $green1;
			font-weight: bold;
			min-width: 300px;
			position: relative;
			z-index: 99;
		}
		&:after {
			position: absolute;
			content: "";
			top: 50%;
			background: #fff;
			height: 1px;
			width: 100%;
			left: 0;
			right: 0;
		}
	}
	.box1 {
		margin-bottom: 77px;
	}
	.list-taxi {
		font-size: 0;
		margin: 0 -52px;
		text-align: center;
		@include max-screen(1024px) { 
    		margin: 0 -15px;
    	}
		li {
			width: 180px;
			margin: 0 39px;
			display: inline-block;
			vertical-align: top;
			.pic {
				position: relative;
				margin-bottom: 15px;
				&:before {
					position: absolute;
					content: "";
					left: -45px;
					top: 50%;
					background: url(../../img/common/icon/icn-arr1.svg) no-repeat center center;
					width: 15px;
					height: 15px;
					z-index: 99;
					margin-top: -7px;
				}
				&-desc {
					color: #fff;
					font-weight: bold;
					font-size: 16px;
					&1 {
						margin: 0 -30px;
					}
				}
			}
			&:first-child {
				.pic {
					&:before {
						display: none;
					}
				}
			}
		}
	}
}
.sec-outline {
	@include transform(skew(0deg, 0deg));
	margin-top: -40px;
	.bg-skew-content {
		@include transform(skew(0deg, 0deg));
	}
	.outline-box {
		background: #fff;
		@include border-radius(15px);
		padding: 47px 70px 70px 70px;
		margin-bottom: 20px;
	}
	.ttl-outline {
		position: relative;
		font-size: 30px;
		color: $green1;
		font-weight: bold;
		font-family: $plus;
		padding-left: 22px;
		line-height: 1.4;
		margin-bottom: 25px;
		&:before {
			position: absolute;
			content: "";
			left: 0;
			top: 9%;
			height: 85%;
			width: 4px;
			background: $green1;
			@include border-radius(5px);
		}
	}
	.rows1 {
		@include flexbox();
		@include flex-wrap(wrap);
		@include justify-content(space-between);
	}
	.detail {
		width: 72%;
		max-width: calc(100% - 315px);
	}
	.logo-box {
		ul {
			width: 270px;
			li {
				float: left;
				width: 60px;
				margin: 0 10px 10px 0;
				&:nth-child(4n) {
					margin-right: 0;
				}
			}
		}
	}
	.access-box {
		width: 376px;
	}
	.list-note {
		text-align: center;
		font-size: 0;
		margin: -8px -5px 20px -5px;
		li {
			display: inline-block;
			vertical-align: top;
			margin: 0 5px;
			.inner {
				display: table;
				border: 1px solid $green1;
				text-align: center;
				font-size: 13px;
				font-weight: bold;
				height: 48px;
				width: 183px;
				@include border-radius(5px);
				span {
					display: table-cell;
					vertical-align: middle;
				}
			}
			&.taxi {
				.inner {
					color: $green1;
					background: $lightgreen;
				}
			}
			&.bike {
				.inner {
					color: #fff;
					background: $green1;
				}
			}
		}

	}
	.map {
		text-align: center;
		margin: auto;
		max-width: 319px;
		padding-right: 13px;
	}
	.outline-box2 {
		padding: 47px 65px 25px 70px;
		.detail {
			max-width: calc(100% - 430px);
		}
	}
	.outline-box3 {
		padding: 47px 65px 60px 70px;
		.detail {
			max-width: 100%;
			width: 100%;
		}
	}
	.list-dot {
		li {
			position: relative;
			font-size: 15px;
			padding-left: 23px;
			letter-spacing: 1px;
			line-height: 1.75;
			&:before {
				position: absolute;
				content: "";
				left: 0;
				top: 8px;
				width: 10px;
				height: 10px;
				background: #333333;
				@include border-radius(5px);
			}
		}
	}
}
.sec-btn-faq {
	margin: 90px auto auto auto;
	max-width: 525px;
	a {
		display: block;
		color: #fff;
		font-size: 0;
		background: #DAB87B;
		padding: 10px 65px 10px 37px;
		@include border-radius(20px);
		position: relative;
		&:after {
			position: absolute;
			content: "";
			right: 35px;
			margin-top: -7px;
			top: 50%;
			background: url(../../img/common/icon/icn-arr4.svg) no-repeat center center;
			width: 30px;
			height: 15px;
		}
		.icon {
			display: inline-block;
			margin: 0 35px 0 0;
			vertical-align: middle;
			img {
				width: 133px;
			}
		}
		.txt {
			font-size: 34px;
			font-weight: bold;
			display: inline-block;vertical-align: middle;
			max-width: calc(100% - 170px);
		}
	}
}
@include SP {
	.box-scroll {
		display: none;
	}
	.mainVisual {
		padding: 35px 0 0 0;
		margin-bottom: 0;
		min-height: auto;
		height: auto;
		.wcm {
		    height: auto;
		    min-height: auto;
		}
		.box-intro {
			position: relative;
			top: 0%;
			left: 0;
			right: 0;
			-moz-transform: translateY(0%);
		    -webkit-transform: translateY(0%);
		    -ms-transform: translateY(0%);
		    transform: translateY(0%);
		    max-width: auto;
		    margin: auto;
			display: block;
			padding: 0 0 30px 0;
		    .pic {
		    	max-width: 134px;
		    	width: 43%;
		    	margin: 30px auto auto;
		    }
		    .info {
		    	margin-top: 0;
		    	margin-bottom: 55px;
		    }
		    .logo {
		    	margin-top: 0;
		    	margin-bottom: 20px;
		    	img {
		    		max-width: 192px;
		    	}
		    }
		    .desc {
		    	@include font-size(24);
		    	.logo-app {
				    margin: 25px 0 15px 0;
				}
		    	.ttl1 {
		    		@include font-size(24);
		    	}
		    	.ttl2 {
		    		@include font-size(34);
		    		margin-top: 2px;
		    	}
		    	span {
		    		margin-right: 10px;
		    	}
		    	@include max-screen(360px) {
					@include font-size(22);
			    	.ttl2 {
			    		@include font-size(30);
			    	}
				}
		    	@include max-screen(320px) {
					@include font-size(20);
			    	.ttl2 {
			    		@include font-size(28);
			    	}
				}
				.small {
					@include max-screen(320px) {
						@include font-size(14);
					}
				}
		    }
		}
	}
	.sec-feature {
		padding-bottom: 80px;
		.rows {
			display: block;
			.thumb {
				padding: 0 30px;
				width: 100%;
				max-width: 100%;
				margin-bottom: 35px;
			}
			.detail {
				width: 100%;
				ul {
					li {
						margin-bottom: 18px;
						width: 100%;
						min-height: auto;
						display: block;
						.heading {
							background: rgba(255,255,255,0.2);
							@include border-radius(10px);
							display: table;
							padding: 8px 20px;
							width: 100%;
							.right-ttl {
								vertical-align: middle;
								display: table-cell;
								padding-left: 20px;
								@include max-screen(320px) {
						        	@include font-size(24);
						      	}
							}
						}
						.right {
							padding: 0;
							display: block;
							p {
								padding: 20px;
							}
							.pic {
								background: none;
								width: 35px;
								vertical-align: middle;
								text-align: center;padding: 0;
							}
						}

					}
				}
			}
		}
	}
	.sec-bike {
		.content-accor {
			display: none;
		}
		.cmn-title {
			margin-bottom: 40px;
			.ttl {
				small {
					margin-bottom: 5px;
				}
			}
		}
		.block-step {
			display: block;
			background: #fff;
			@include border-radius(10px);
			.step-num {
				margin: 0;
				position: relative;
				width: 100%;
				@include border-radius(10px);
				background: $green2;
				display: block;
				padding: 3px 0;
				&:after {
					position: absolute;
					content: "";
					top: 50%;
					right: 25px;
					width: 16px;
					height: 24px;
					margin-top: -13px;
					background: url(../../img/common/icon/icn-arr2.svg) no-repeat center center;
					background-size: 16px 24px;
					@include add_prefix(transition, 0.3s ease all);
				}
			}
			.ttl-accor.is-active {
				.step-num {
					background: #067261;
					@include border-radius(10px 10px 0 0);
					&:after {
						transform: rotate(180deg);
					}
				}
			}
			.ttl-step {
				@include max-screen(320px) {
		        	@include font-size(27);
		      	}
			}
		}
		.content-accor {
			margin-bottom: 12px;
			padding: 40px 15px;
		}
		.block-step1 {
			max-width: 100%;
			margin: auto auto 12px auto;
			.content-accor {
				flex: none;
				display: none;
			}
			.pic {
				max-width: 163px;
				margin: auto;
				width: 100%;
			}
			.detail {
				text-align: center;
				margin-top: 0;
				width: 100%;
				margin-bottom: 50px;
			}
			.step-num {
				margin-bottom: 0;
			}
		}
		
		.list-bike {
			font-size: 0;
			text-align: center;
			display: block;
			li {
				width: 100%;
				display: block;
				padding: 0;
				margin: auto;
				margin-bottom: 40px;
				max-width: 100%;
				&:last-child {margin-bottom: 0;}
				.thumb {
					max-width: 250px;
					position: relative;
					text-align: center;
					margin: auto auto 20px auto;
					text-align: center;
					@include max-screen(320px) {
			        	max-width: 200px;
			      	}
					&.thumb2 {
						margin: -18px 0 20px 0;
						max-width: 100%;
					}
					.per {
						position: absolute;
						content: "";
					    right: -108px;
					    bottom: -27px;
					}
					&.thumb3 {
						margin: 50px auto 20px auto;
					}
					&.thumb4 {
					}
					.note {
						right: -31px;
						@include max-screen(320px) {
							width: 160px;
				        	right: -20px;font-size: 13px;
				      	}
					}
				}
				.desc {
					@include max-screen(320px) {
			        	font-size: 15px;
			      	}
				}
				.num01 {
					span {
						font-size: 16px;
						line-height: 27px;
						height: 25px;
						width: 50px;
					}
				}
				.cir {
					min-height: 250px;
				}
			}
		}
		.block-step-white {
			padding: 0;
			margin-bottom: 12px;
			.heading {
				max-width: 900px;
				text-align: center;
				margin: auto auto 40px auto;
				.ttl-step {
					margin-bottom: 0;
					max-width: 100%;
					font-size: 33px;
					@include max-screen(320px) {
			        	font-size: 30px;
			      	}
				}
			}
			&.step4 {
				.note-pink {
					margin: 40px 0 10px 0;
					padding: 0 10px;
					@include max-screen(320px) {
			        	font-size: 14px;
			      	}
				}
				.heading {
					margin: auto auto 45px auto;
				}
				.list-bike {
					li {
						.thumb {
							margin-bottom: 25px;
						}
					}
				}
			}
			&.step5 {
				.heading {
					margin: auto auto 20px auto;
				}
				.desc1 {
					margin: 42px 17px 40px 17px;
				}
				.pic {
					width: 100%;
					padding: 0 7px;
				}
				.box-img {
					margin-bottom: 0;
					.line01 {
						bottom: -30px;
					}
					&-desc {
						top: auto;
						font-size: 16px;
						bottom: 0;
						margin: 30px auto auto;
					}
				}
			}
		}
	}
	.sec-taxi {
		.cmn-title {
			margin-bottom: 50px;
		}
		.ttl-taxi {
			margin-bottom: 35px;
			@include max-screen(320px) {
	        	span {
	        		font-size: 26px;
	        		    min-width: 270px;
	        	}
	      	}
		}
		.box1 {
			margin-bottom: 20px;
		}
		.box2 {
			overflow: auto;
			&::-webkit-scrollbar  {
			    display: none;
			    height: 0;
			    width: 0;
			}
			ul {
				width: 130%;
				@include max-screen(480px) {
		        	width: 270%;
		      	}
		      	@include max-screen(375px) {
		        	width: 270%;
		      	}
				li {
					width: 20%;
				}
			}
		}
		.list-taxi {
			font-size: 0;
			margin: 0 -15px;
			text-align: center;
			li {
				width: 50%;
				margin: 0;
				padding: 0 17px;
				margin-bottom: 30px;
				max-width: 185px;
				@include max-screen(480px) {
		        	max-width: 300px;
		      	}
		      	@include max-screen(375px) {
		        	max-width: 185px;
		      	}
				.pic {
					margin-bottom: 12px;
					&:before {
						left: auto;
						right: -26px;
					}
					&-desc {
						&1 {
							margin: 0;
						}
						@include max-screen(320px) {
				        	font-size: 15px;
				      	}
					}
				}
				&:last-child {
					.pic {
						&:before {
							display: none;
						}
					}
				}
				&:first-child {
					.pic {
						&:before {
							display: block;
						}
					}
				}
			}
		}
	}
	.sec-outline {
		.outline-box {
			padding: 32px 20px 20px 20px;
			margin-bottom: 20px;
			@include border-radius(20px);
		}
		.rows1 {
			display: block;
		}
		.detail {
			width: 100%;
			max-width: 100%;
			margin-bottom: 18px;
		}
		.logo-box {
			padding: 0 7px 0 18px;
			ul {
				width: 100%;
				li {
					float: left;
					width: 25%;
					padding: 0 10px 10px 0;
					margin: 0;
				}
			}
		}
		.access-box {
			width: 100%;
			margin-top: 28px;
		}
		.list-note {
			text-align: center;
			font-size: 0;
			margin: 0 0 30px 0;
			li {
				display: block;
				margin: 0 0 9px 0;
				.inner {
					width: 100%;
				}
			}

		}
		.map {
			max-width: 100%;
			padding-right: 0;
		}
		.outline-box2 {
			padding: 30px 20px 30px 20px;
			.detail {
				max-width: 100%;
			}
		}
		.outline-box3 {
			padding: 30px 20px 15px 20px;
			.detail {
				max-width: 100%;
				width: 100%;
			}
		}
		.list-dot {
			li {
				position: relative;
				font-size: 15px;
				padding-left: 23px;
				letter-spacing: 1px;
				line-height: 1.75;
				&:before {
					position: absolute;
					content: "";
					left: 0;
					top: 8px;
					width: 10px;
					height: 10px;
					background: #333333;
					@include border-radius(5px);
				}
			}
		}
	}
	.sec-btn-faq {
	    margin: 52px auto auto auto;
	    max-width: 100%;
	    a {
	    	padding: 19px 10px;
	    	text-align: center;
	    	.icon {
			    display: block;
			    margin: 0 0 10px 0;
			    width: 100%;text-align: center;
			}
			.txt {
			    max-width: 100%;
			    position: relative;
			    padding-right: 40px;
			    @include max-screen(320px) {
					@include font-size(28);
				}
			    &:after {
				    position: absolute;
				    content: "";
				    right: 0;
				    margin-top: -7px;
				    top: 50%;
				    background: url(../../img/common/icon/icn-arr4.svg) no-repeat center center;
				    width: 30px;
				    height: 15px;
				}
			}
			&:after {
			    display: none;
			}
	    }
	}
}
@include PC {
	.sec-feature.bg-skew {
		overflow:inherit;
	}
	.sec-feature.bg-skew.bg-bubble1 .bg-skew-content:after {
	    margin-top: 40px;
	}
}